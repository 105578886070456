// Loader.js
import React from "react";
import "../Loader.css"; // Make sure to create corresponding CSS for the loader

const Loader = () => {
  return (
    <div className="loader-overlay">
      <img src="favicon-32x32.png" alt=""></img>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
