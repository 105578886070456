import React from "react";

function SNU_MODULAR_UNIVERSAL_MOUNTING() {
  return (
    <>
      <div className="container p-5">
        <h1>SNU MODULAR UNIVERSAL MOUNTING</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/SNU-MODULAR-UNIVERSAL-MOUNTING.png"></img>
          </div>

          <div className="col-md-7 mt-5">
          <p className="fs-1">
              <b> Size</b> : 1 5/8, 1¾, 2, 2½, 3, 3½,4, 5, 6, 7, 8, 9, 10.5
            </p>
            <p className="fs-1">
              <b> Underdriven</b> : SNU-U
            </p>
            <p className="fs-1">
              {" "}
              <b>Overdriven</b> : SNU-O
            </p>
            <p className="fs-1">
              {" "}
              <b>Verticle output shaft up/down</b> : SNU-V
            </p>
            <p className="fs-1">
              {" "}
              <b> Hollow output shaft	</b> : SNU-SM
            </p>
            <p className="fs-1">
              {" "}
              <b> Ratios</b> : Up to 20,000:1
            </p>
            <p className="fs-1">
              {" "}
              <b>Power Capacity </b> : 139 kW
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SNU_MODULAR_UNIVERSAL_MOUNTING;
