import React from "react";

function HIGHLY_FLEXIBLE_COUPLINGS() {
  return (
    <>
      <div className="container p-5">
        <h1>HIGHLY FLEXIBLE COUPLINGS</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/HIGHLY-FLEXIBLE-COUPLINGS.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Size</b> : 09
            </p>           
            <p className="fs-1">
              <b> Torque</b> : 1550 Nm to 89500 Nm
            </p>
            <p className="fs-1">
              <b>Ambient Temperatures </b> : 40 °C and +80 °C
            </p>           
          </div>
          <div className="col-md-12">
            <p className="fs-2 mt-5 text-justify">
            The split flexible rubber rings can be changed without having to move the 
coupled machines. The flexible rings are mounted without backlash and 
give the coupling progressive torsional stiffness.The HFC-A coupling is utilized in tough operating conditions 
to ensure long service life.

            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HIGHLY_FLEXIBLE_COUPLINGS;
