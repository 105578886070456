import React from "react";

function EON_SERIES() {
  return (
    <>
      <div className="container p-5">
        <h1>EON SERIES (HELICAL AND BEVEL HELICAL GEARBOXES)</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/EON-SERIES.png"></img>
          </div>

          <div className="col-md-7 mt-5">
          <p className="fs-2 mt-5 text-justify">
            The EON series offers a comprehensive range of right 
angle and parallel shaft combinations with ratios to suit 
market requirement. Both solid and hollow output shaft 
combination are available including keyed, keyless 
including double extended output shaft.
            </p>
          </div>
          <div className="col-md-12">
            
          </div>
        </div>
      </div>
    </>
  );
}

export default EON_SERIES;
