import React from "react";

function HELICAL_INLINE_GEARED_MOTOR() {
  return (
    <>
      <div className="container p-5">
        <h1>SERIES M - HELICAL INLINE GEARED MOTOR</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/HELICAL-INLINE-GEARED-MOTOR.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Sizes</b> : 01, 02, 03, 04, 05, 06, 07, 08, 09,10, 13, 14, 16{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Input Power</b> : Up to 160kW
            </p>
            <p className="fs-1">
              {" "}
              <b>Output Torque</b> : Up to 20,000 N-m{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Gear Ratios</b> : Up to 20,000:1
            </p>
            <p className="fs-1">
              {" "}
              <b>Motor Sizes IEC Frames from </b> : 63 to 315
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HELICAL_INLINE_GEARED_MOTOR;
