import React from "react";

function CRANE_DUTY_GEAR_BOX() {
  return (
    <>
      <div className="container p-5">
        <h1>CRANE DUTY GEAR BOX</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/CRANE-DUTY-GEAR-BOX.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Size Range </b> : 140 mm to 490 mm (Last Stage CD in mm)
            </p>
            <p className="fs-1">
              {" "}
              <b>Type of Gear </b> : Helical 3-Stage & 4- Stage
            </p>
            <p className="fs-1">
              {" "}
              <b>Gearbox Units </b> : Size 16 to 27 
            </p>
            <p className="fs-1">
              {" "}
              <b>Ratio Range </b> :  14 to 280
            </p>
            <p className="fs-1">
              {" "}
              <b>Torque Range </b> : 3.2 kNm to 130 kNm
            </p>
          </div>
          <div className="col-md-12">
            <p className="fs-2 mt-5 text-justify">
            “A- Series” as per the Industry requirements, it offer greater 
flexibility and has been developed keeping in mind crane 
industry requirements and offers the greater flexibility, 
advantages with wide range of future. It has specially designed 
for drives of transporting equipment, lifting gears cranes and 
elevators. designed “A- series” for maintain balance Thermal 
& Mechanical.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CRANE_DUTY_GEAR_BOX;
