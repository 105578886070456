import React from "react";

function PARALLEL_SHAFT_MOUNTED_GEARED_MORTOR() {
  return (
    <>
      <div className="container p-5">
        <h1>SERIES F - PARALLEL SHAFT MOUNTED GEARED MOTOR</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/PARALLEL-SHAFT-MOUNTED-GEARED-MOTOR.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Sizes</b> : 02, 03, 04, 05, 06, 07, 08, 09, 10{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Input Power</b> : Up to 45 kW
            </p>
            <p className="fs-1">
              {" "}
              <b>Output Torque</b> : Upto 10,000 N-m{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Gear Ratios</b> : Upto 20,000:1
            </p>
            <p className="fs-1">
              {" "}
              <b>Motor Sizes IEC Frames from </b> : 63 to 225
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PARALLEL_SHAFT_MOUNTED_GEARED_MORTOR;
