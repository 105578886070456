import React from "react";
import useContactForm from "../CustomHook/useContactForm";

function ContactUs2() {
  const { formData, isSubmitting, error, handleChange, handleSubmit } =
    useContactForm();

  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/contactus.jpg)" }}
      >
        <div className="auto-container">
          <h1>Contact Us</h1>
          <ul className="page-breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </section>

      <section className="contact-section-three">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="carousel-column col-md-5 col-sm-12 col-xs-12">
              <div className="inner-column">
                <div className="branches-carousel">
                  <div className="slide">
                    <div className="branch-info-box">
                      <div className="row clearfix">
                        <div className="info-column col-md-12 col-sm-12 col-xs-12">
                          <div className="lucation">Registered Office</div>
                          <ul>
                            <li>
                              <span className="icon flaticon-place"></span>
                              <h5>KVM House F-152 Transport Nagar</h5>
                              <p>Lucknow-226012 Uttar Pradesh India</p>
                            </li>

                            <li>
                              <span className="icon flaticon-support"></span>
                              <h5>Call Us On</h5>
                              <p>
                                <a href="#">+91 9651103223</a>
                              </p>
                            </li>

                            <li>
                              <span className="icon flaticon-plane"></span>
                              <h5>Mail Us at</h5>
                              <p>
                                <a href="#">info@kvmgroup.in</a>
                              </p>
                            </li>
                            <li>
                              <span className="icon flaticon-watch"></span>
                              <h5>Working Hours</h5>
                              <p>Mon-Sat : 10.00 am to 06.00 pm</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-info-box">
                      <div className="row clearfix">
                        <div className="info-column col-md-12 col-sm-12 col-xs-12 mt-5">
                          <div className="lucation mt-5 pt-5">
                            Branch Offices
                          </div>
                          <ul className="d-flex myul">
                            <li>
                              <span className="icon flaticon-support "></span>
                              <h5>Kanpur</h5>
                              <p>
                                <a href="#">+91 9651103226</a>
                              </p>
                            </li>
                            <li>
                              <span className="icon flaticon-support "></span>
                              <h5>Gorakhpur</h5>
                              <p>
                                <a href="#">+91 9580506869</a>
                              </p>
                            </li>
                            <li>
                              <span className="icon flaticon-support  "></span>
                              <h5>Saharanpur</h5>
                              <p>
                                <a href="#">+91 9580506866</a>
                              </p>
                            </li>
                            <li>
                              <span className="icon flaticon-support "></span>
                              <h5>Sonebhadra</h5>
                              <p>
                                <a href="#">+91 7698137220</a>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7 col-sm-12 col-xs-12 mt-5">
              <div className="sec-title mt-4">
                <h2>Request For Free Quote</h2>
                <div className="text">
                  For Business inquiry fill our feedback form. Our team will
                  help you with in 24 hours.
                </div>
              </div>

              <div className="contact-form style-three">
                <form
                  method="post"
                  action=""
                  id="contact-form"
                  onSubmit={handleSubmit}
                >
                  <div className="row clearfix">
                    <div className="left-column col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          name="username"
                          value={formData.username}
                          placeholder="Your Name"
                          required
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          placeholder="Email Address"
                          required
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>Subject</label>
                        <input
                          type="text"
                          name="mobile"
                          value={formData.mobile}
                          placeholder="Mobile No"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <textarea
                        name="message"
                        placeholder="Message"
                        className="bg-light p-4"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="column col-md-12 col-sm-6 col-xs-12">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="theme-btn"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit Now"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {error && <p>{error}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs2;
