import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./owlcarasol.css";
import { Link } from "react-router-dom";

function Ourproducts() {
  const options = {
    items: 3,
    margin: 30,
    loop: true,
    nav: true,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ], // Use Font Awesome icons
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    dots: false,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <section
      className="project-section"
      style={{ backgroundImage: "url(images/background/1.jpg)" }}
    >
      <div className="auto-container">
        <div className="sec-title light text-center">
          <h4>Fast Moving </h4>
          <h2>Products</h2>
        </div>

        <div className="carousel-box">
          <OwlCarousel className="project-carousel owl-theme" {...options}>
            <div className="project-block">
              <div className="image-box">
                <figure>
                  <a className="ligtbox-image" data-fancybox="gallery">
                    <img src="images/ProImages/11.jpg" alt=""></img>
                  </a>
                </figure>
                <div className="title-box">
                  <h3>
                    <Link to="/WORM_GEAR_SMALL_SERIES">
                      WORM GEAR HIGHER <br></br>SERIES
                    </Link>
                  </h3>
                  <span className="tag">Gear Box</span>
                </div>
              </div>
            </div>

            <div className="project-block">
              <div className="image-box">
                <figure>
                  <a className="ligtbox-image" data-fancybox="gallery">
                    <img src="images/ProImages/22.jpg" alt=""></img>
                  </a>
                </figure>
                <div className="title-box">
                  <h3>
                    <Link to="/HELICAL_INLINE_GEARED_MOTOR">
                      SERIES M - HELICAL INLINE GEARED MOTOR
                    </Link>
                  </h3>
                  <span className="tag">Gear Motor</span>
                </div>
              </div>
            </div>

            <div className="project-block">
              <div className="image-box">
                <figure>
                  <a className="ligtbox-image" data-fancybox="gallery">
                    <img src="images/ProImages/33.jpg" alt=""></img>
                  </a>
                </figure>
                <div className="title-box">
                  <h3>
                    <Link to="/RIGHT_ANGLED_HELI_WORM_GEARED_MOTOR">
                      SERIES C - RIGHT ANGLED HELI-WORM GEARED MOTOR
                    </Link>
                  </h3>
                  <span className="tag">Gear Motor</span>
                </div>
              </div>
            </div>

            <div className="project-block">
              <div className="image-box">
                <figure>
                  <a className="ligtbox-image" data-fancybox="gallery">
                    <img src="images/ProImages/44.jpg" alt=""></img>
                  </a>
                </figure>
                <div className="title-box">
                  <h3>
                    <Link to="/DOUBLE_REDUCTION_GEAR">
                      DOUBLE<br></br> REDUCTION GEAR
                    </Link>
                  </h3>
                  <span className="tag">Gear Box</span>
                </div>
              </div>
            </div>

            <div className="project-block">
              <div className="image-box">
                <figure>
                  <a className="ligtbox-image" data-fancybox="gallery">
                    <img src="images/ProImages/55.jpg" alt=""></img>
                  </a>
                </figure>
                <div className="title-box">
                  <h3>
                    <Link to="/RIGHT_ANGLE_HELICAL_BEVEL_GEARED_MOTOR">
                      SERIES K - RIGHT ANGLE HELICAL BEVEL GEARED MOTOR
                    </Link>
                  </h3>
                  <span className="tag">Gear Motor</span>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

export default Ourproducts;
