import React from "react";

function DOUBLE_REDUCTION_GEAR() {
  return (
    <>
      <div className="container p-5">
        <h1>DOUBLE REDUCTION GEAR</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/DOUBLE-REDUCTION-GEAR.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Sizes</b> : 1¾/30, 2¼ /40, 2¼ /50, 3/60, 3/70, 4/80, 4/90, 5/105, 5/120, 6/140 , 7/170
            </p>
            <p className="fs-1">
              {" "}
              <b>Underdriven</b> : SNU-UD / SFUD
            </p>
            <p className="fs-1">
              {" "}
              <b>Overdriven</b> : SNU-OD / SFOD
            </p>
            <p className="fs-1">
              {" "}
              <b>Verticle output shaft</b> : SNU-VD / SFVD ( UP/down )
            </p>
            <p className="fs-1">
              {" "}
              <b>Hollow output shaft </b> : SNU-SMD /SSMD
            </p>
            <p className="fs-1">
              {" "}
              <b>Ratio </b> : Min. 75 : 1 to Max 4900 : 1
            </p>
            <p className="fs-1">
              {" "}
              <b>Power Capacity	</b> : 50 kW
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DOUBLE_REDUCTION_GEAR;
