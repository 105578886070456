import React, { useState, useEffect, useRef } from "react";
import mixitup from "mixitup";
import styles from "./Products.module.css";
import { useNavigate } from "react-router-dom";

const ProductsMixRouting = () => {
  const [filterKey, setFilterKey] = useState("all");
  const mixitupInstance = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const mixitupContainer = document.querySelector(".mixitup-container");

    if (mixitupContainer) {
      mixitupInstance.current = mixitup(mixitupContainer, {
        selectors: {
          target: ".mixitup-item",
        },
        animation: {
          duration: 600,
        },
      });
    }

    return () => {
      if (mixitupInstance.current) {
        mixitupInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (mixitupInstance.current) {
      mixitupInstance.current.filter(
        filterKey === "all" ? "all" : `.${filterKey}`
      );
    }
  }, [filterKey]);

  const handleFilterChange = (key) => {
    setFilterKey(key);
  };

  const portfolioItems = [
    {
      category: "gm",
      imgSrc: "../images/ProImages/HOIST-GEARED-MOTOR.png",
      title: "SERIES H - HOIST GEARED MOTOR",
      link: "images/1.png",
      path: "HOIST_GEARED_MOTOR",
      tags: ["GEARED MOTOR"],
    },

    {
      category: "gb gm",
      imgSrc: "../images/ProImages/COMPACT-GEARED-MOTOR.png",
      title: "SERIES A - COMPACT GEARED MOTOR ",
      link: "images/1.png",
      path: "COMPACT_GEARED_MOTOR",
      tags: ["GEAR BOX & GEARED MOTOR"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/HELICAL%20GEARBOXES.png",
      title: "HELICAL GEARBOXES",
      link: "images/1.png",
      path: "HELICAL_GEARBOXES",
      tags: ["GEAR BOX"],
    },
    {
      category: "gb gm",
      imgSrc: "images/ProImages/RIGHT-ANGLED-HELI-WORM-GEARED-MOTOR.png",
      title: "SERIES C - RIGHT ANGLED HELI-WORM GEARED MOTOR",
      link: "images/1.png",
      path: "RIGHT_ANGLED_HELI_WORM_GEARED_MOTOR",
      tags: ["GEAR BOX & GEARED MOTOR"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/EON-SERIES.png",
      title: "EON SERIES ",
      link: "images/1.png",
      path: "EON_SERIES",
      tags: ["GEAR BOX"],
    },

    {
      category: "gb",
      imgSrc: "../images/ProImages/CRANE-DUTY-GEAR-BOX.png",
      title: "CRANE DUTY GEAR BOX",
      link: "images/1.png",
      path: "CRANE_DUTY_GEAR_BOX",
      tags: ["GEAR BOX"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/WORM-GEAR-HIGHER-SERIES.png",
      title: "WORM GEAR HIGHER SERIES",
      link: "images/1.png",
      path: "WORM_GEAR_HIGHER_SERIES",
      tags: ["GEAR BOX"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/WORM-GEAR-SMALL-SERIES.png",
      title: "WORM GEAR SMALL SERIES",
      link: "images/1.png",
      path: "WORM_GEAR_SMALL_SERIES",
      tags: ["GEAR BOX"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/DER-RADICON-SERIES.png",
      title: "DER 'RADICON' SERIES ",
      link: "images/1.png",
      path: "DER_RADICON_SERIES",
      tags: ["GEAR BOX"],
    },
    {
      category: "gb gm",
      imgSrc: "../images/ProImages/PLANETARY-GEARED-MOTOR.png",
      title: "SERIES PL - PLANETARY GEARED MOTOR",
      link: "images/1.png",
      path: "PLANETARY_GEARED_MOTOR",
      tags: ["GEAR BOX & GEARED MOTOR"],
    },
    {
      category: "gb gm",
      imgSrc: "../images/ProImages/HELICAL-INLINE-GEARED-MOTOR.png",
      title: "SERIES M - HELICAL INLINE GEARED MOTOR",
      link: "images/1.png",
      path: "HELICAL_INLINE_GEARED_MOTOR",
      tags: ["GEAR BOX & GEARED MOTOR"],
    },
    {
      category: "c",
      imgSrc: "../images/ProImages/HIGHLY-FLEXIBLE-COUPLINGS.png",
      title: "HIGHLY FLEXIBLE COUPLINGS ( HFC-A) ",
      link: "images/1.png",
      path: "HIGHLY_FLEXIBLE_COUPLINGS",
      tags: ["COUPLING"],
    },
    {
      category: "c",
      imgSrc: "../images/ProImages/FLUID-COUPING.png",
      title: "FLUID COUPING  ",
      link: "images/1.png",
      path: "FLUID_COUPLING",
      tags: ["COUPLING"],
    },
    {
      category: "gb gm",
      imgSrc: "../images/ProImages/RIGHT-ANGLE-HELICAL-BEVEL-GEARED-MOTOR.png",
      title: "SERIES K - RIGHT ANGLE HELICAL BEVEL GEARED MOTOR",
      link: "images/1.png",
      path: "RIGHT_ANGLE_HELICAL_BEVEL_GEARED_MOTOR",
      tags: ["GEAR BOX & GEARED MOTOR"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/SNU-MODULAR-UNIVERSAL-MOUNTING.png",
      title: "SNU MODULAR UNIVERSAL MOUNTING ",
      link: "images/1.png",
      path: "SNU_MODULAR_UNIVERSAL_MOUNTING",
      tags: ["GEAR BOX"],
    },
    {
      category: "gb gm",
      imgSrc: "../images/ProImages/PARALLEL-SHAFT-MOUNTED-GEARED-MOTOR.png",
      title: "SERIES F - PARALLEL SHAFT MOUNTED GEARED MOTOR",
      link: "images/1.png",
      path: "PARALLEL_SHAFT_MOUNTED_GEARED_MORTOR",
      tags: ["GEAR BOX & GEARED MOTOR"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/DOUBLE-REDUCTION-GEAR.png",
      title: "DOUBLE REDUCTION GEAR ",
      link: "images/1.png",
      path: "DOUBLE_REDUCTION_GEAR",
      tags: ["GEAR BOX"],
    },
    {
      category: "c",
      imgSrc: "../images/ProImages/ELIGN-GEARED-COUPLING.png",
      title: "ELIGN GEARED COUPLING ",
      link: "images/1.png",
      path: "ELIGN_GEARED_COUPLING",
      tags: ["COUPLING"],
    },
    {
      category: "gb",
      imgSrc: "../images/ProImages/WORM-GEAR-REDUCERS.png",
      title: "PBWR SERIES - WORM GEAR REDUCERS",
      link: "images/1.png",
      path: "WORM_GEAR_REDUCERS",
      tags: ["GEAR BOX"],
    },
    {
      category: "c",
      imgSrc: "../images/ProImages/ELFLEX-FLEXIBLE-COUPLING.png",
      title: "ELFLEX FLEXIBLE COUPLING",
      link: "images/1.png",
      path: "ELFLEX_FLEXIBLE_COUPLING",
      tags: ["COUPLING"],
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-12 ${styles.customepadding}`}>
          <div className={`filters ${styles.isotope_filters}`}>
            <a
              href="#"
              className={filterKey === "all" ? styles.selectedFilter : ""}
              onClick={(e) => {
                e.preventDefault();
                handleFilterChange("all");
              }}
            >
              All
            </a>
            <a
              href="#"
              className={filterKey === "gb" ? styles.selectedFilter : ""}
              onClick={(e) => {
                e.preventDefault();
                handleFilterChange("gb");
              }}
            >
              GEAR BOX
            </a>
            <a
              href="#"
              className={filterKey === "gm" ? styles.selectedFilter : ""}
              onClick={(e) => {
                e.preventDefault();
                handleFilterChange("gm");
              }}
            >
              GEARED MOTOR
            </a>
            <a
              href="#"
              className={filterKey === "c" ? styles.selectedFilter : ""}
              onClick={(e) => {
                e.preventDefault();
                handleFilterChange("c");
              }}
            >
              COUPLING
            </a>
          </div>

          <div className={`mixitup-container ${styles.isotopecontainer}`}>
            {portfolioItems.map((item, index) => (
              <div
                key={index}
                className={`mixitup-item ${styles.isotopitem} ${item.category
                  .split(" ")
                  .join(" ")}`}
              >
                <div className={`${styles.imgmedia}`}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/${item.path}`);
                    }}
                  >
                    <img src={item.imgSrc} alt={item.title} />
                  </a>
                </div>
                <div className={`${styles.itemcontent}`}>
                  <header>
                    <div className={`${styles.itemccategorylink}`}>
                      {item.tags.map((tag, idx) => (
                        <a key={idx} href="#">
                          {tag}
                        </a>
                      ))}
                    </div>
                    <h4 className="entry-title">{item.title}</h4>
                  </header>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsMixRouting;
