import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="client-carousel-wrapper">
        <div className="auto-container">
          <div className="clients-carousel d-flex justify-content-center">
            <h2 className="fw-bold text-white h2Category">GEAR BOX</h2>
            <h2 className="fw-bold text-white h2Category">COUPLINGS</h2>
            <h2 className="fw-bold text-white h2Category"> GEARED MOTORS</h2>
          </div>
        </div>
      </section>
      <footer className="main-footer padding-top">
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">
              <div className="footer-column col-md-4 col-sm-6 col-xs-12">
                <div className="logo">
                  <a href="index.html">
                    <img src="images/logo1w.png" alt="" width={"90%"}></img>
                  </a>
                </div>
                <div className="footer-widget subscribe-widget">
                  <div className="widget-content">
                    <div className="text">
                      <h4>Let’s join & create something together</h4>
                      <p>
                        We integrate cutting-edge technology and stringent
                        quality controls, we've ensured our products meet the
                        highest standards of performance and reliability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-column col-md-4 col-sm-6 col-xs-12 ">
                <div className="footer-widget links-widget">
                  <div className="widget-title">
                    <h2>Useful Links</h2>
                  </div>
                  <div className="widget-content">
                    <ul className="list clearfix">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/kvm-surgicals">KVM Surgicals</Link>
                      </li>

                      <li>
                        <Link to="/contacts">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/kvm-surgicals">KVM Organique</Link>
                      </li>

                      <li>
                        <Link to="/products">Products</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="footer-column col-md-4 col-sm-6 col-xs-12">
                <div className="footer-widget contact-widget">
                  <div className="single-contact-widget">
                    <div className="icon-box">
                      <i className="machinery-icon-telephone"></i>
                    </div>
                    <div className="text-box">
                      <h3>For Business Dealing:</h3>

                      <span> +91 9651103223</span>

                      <p>Mon-Sat : 10.00 am to 06.00 pm.</p>
                    </div>
                  </div>
                  <div className="single-contact-widget">
                    <div className="icon-box">
                      <i className="machinery-icon-map"></i>
                    </div>
                    <div className="text-box">
                      <p>
                        KVM House F-152 Transport Nagar Lucknow-226012 Uttar
                        Pradesh India
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-widget">
                    <div className="icon-box">
                      <i className="machinery-icon-send"></i>
                    </div>
                    <div className="text-box">
                      <h3>Mail Us at</h3>
                      <p>info@kvmgroup.in</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container clearfix">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12 text-left"></div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="copyright-text">
                  &copy; 2024 All Rights Reserved by <a>KVM GROUP</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12 text-right"></div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
