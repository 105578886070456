import React from "react";
import useContactForm from "../CustomHook/useContactForm";

function RequestafreeQuate() {
  const { formData, isSubmitting, error, handleChange, handleSubmit } =
    useContactForm();
  return (
    <>
      <section className="request-qoute-title-block bg-gray ">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h4>Ask Our Expert</h4>
            <h2>Request For Free Quote</h2>
            <div className="text">
              Your details are kept strictly confidential as per our company
              privacy policy.
            </div>
          </div>
        </div>
      </section>
      <section className="contact-section request-qoute-content-block mb-10">
        <div className="auto-container">
          <div className="row clearfix no-gutters">
            <div className="image-column col-md-4 col-sm-12 col-xs-12">
              <div className="inner-column">
                <img
                  src="images/resource/rqa-offer-box.jpg"
                  alt="Awesome Image"
                />
                <div className="overlay-box">
                  <div className="inner-box">
                    <div className="content">
                      <h3>
                        Call <br></br>9651103223 for Best Prices{" "}
                      </h3>
                      <a
                        href="tel:+91 9651103223"
                        className="theme-btn btn-style-one"
                      >
                        Call Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-column col-md-8 col-sm-12 col-xs-12">
              <div className="inner-column">
                <div className="text">
                  For Business inquiry fill our feedback form. Our team will
                  help you with <br></br>in 24 hours.
                </div>

                <div className="contact-form">
                  <form method="post" action="" onSubmit={handleSubmit}>
                    <div className="row clearfix">
                      <div className="column col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="username"
                            value={formData.username}
                            placeholder="Your Name"
                            required
                            onChange={handleChange}
                          ></input>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            placeholder="Your Email"
                            required
                            onChange={handleChange}
                          ></input>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="mobile"
                            value={formData.mobile}
                            placeholder="Your Mobile No"
                            required
                            onChange={handleChange}
                          ></input>
                        </div>
                      </div>
                      <div className="column col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            required
                            onChange={handleChange}
                          ></textarea>
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="theme-btn"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit Now"}
                          </button>
                          {error && <p>{error}</p>}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RequestafreeQuate;
