import React from "react";

function DER_RADICON_SERIES() {
  return (
    <>
      <div className="container p-5">
        <h1>DER RADICON SERIE</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/DER-RADICON-SERIES.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Size</b> : 4″ to 17″
            </p>
            <p className="fs-1">
              {" "}
              <b>Rating</b> : 1.1 to 350 kW
            </p> 
            <p className="fs-1">
              {" "}
              <b>Applications</b> : Heavy duty stirrer gear units Cooling Tower gear units.
               Double Reduction Worm-worm, Helical-worm, Worm Geared motors.
            </p> 
            
          </div>
          <div className="col-md-12">
            <p className="fs-2 mt-5 text-justify">
            Mounting and change of Shaft Handling. Achieved easily and quickly without dismantling the gear case.</p>
          <p></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DER_RADICON_SERIES;
