import React from "react";

function HOIST_GEARED_MOTOR() {
  return (
    <>
      <div className="container p-5">
        <h1>SERIES H - HOIST GEARED MOTOR </h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/HOIST-GEARED-MOTOR.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Sizes</b> : H3100, H3200, H3300{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Lifting Capacity</b> : Up to 12.5 Tons
            </p>
            {/* <p className="fs-1">
              {" "}
              <b>Output Torque</b> : Up to 20,000 N-m{" "}
            </p> */}
            <p className="fs-1">
              {" "}
              <b>Gear Ratios</b> : Up to 120:1
            </p>
            <p className="fs-1">
              {" "}
              <b>Motor Sizes IEC Frames from </b> :90 to 160
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HOIST_GEARED_MOTOR;
