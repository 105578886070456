import React from "react";

function COMPACT_GEARED_MOTOR() {
  return (
    <>
      <div className="container p-5">
        <h1>SERIES A - COMPACT GEARED MOTOR </h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/COMPACT-GEARED-MOTOR.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Sizes</b> : 9 Sizes{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Input Power</b> : Up to 11kW
            </p>
            <p className="fs-1">
              {" "}
              <b>Output Torque</b> : Up to 2,764 N-m{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Gear Ratios</b> : Up to 200:1
            </p>
            <p className="fs-1">
              {" "}
              <b>Motor Sizes IEC Frames from </b> :71 to 160
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default COMPACT_GEARED_MOTOR;
