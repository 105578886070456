import React from "react";

function Whatwedo() {
  return (
    <section className="what-we-do">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="content-column col-md-6 col-sm-12 col-xs-12">
            <div className="inner-column">
              <div className="sec-title light">
                <h4>Fun facts</h4>
                <h2>What We Achieved?</h2>
                <h5 className="text-justify">
                  We've achieved remarkable milestones in supplying high-quality
                  gearboxes, gears, and couplings to a diverse clientele. Our
                  commitment to excellence has established us as a trusted
                  partner in the engineering sector. Our innovative solutions
                  have enabled seamless operations in various industries,
                  boosting efficiency and productivity. We pride ourselves on
                  our customer-centric approach, providing tailored solutions
                  and exceptional service. KVM Engineers continues to drive
                  progress, setting new benchmarks in the engineering products
                  supply chain.
                </h5>
              </div>

              <div className="info-box clearfix">
                <span className="icon machinery-icon-worldwide"></span>
                <span className="count">
                  04 <span className="plus">+</span>
                </span>
                <span className="title">
                  <span>Branches</span>
                </span>
              </div>
            </div>
          </div>

          <div className="video-column col-md-6 col-sm-12 col-xs-12">
            <div className="inner-column">
              <figure className="image">
                <img src="images/partners.jpg" alt=""></img>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whatwedo;
