import React from "react";

function PLANETARY_GEARED_MOTOR() {
  return (
    <>
      <div className="container p-5">
        <h1>SERIES PL - PLANETARY GEARED MOTOR</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/PLANETARY-GEARED-MOTOR.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Sizes</b> : PL- 01, 02, 03, 05, 08, 12, 16, 25, 40, 65PR- 01, 02, 03, 05, 08, 12, 16, 25, 40, 65{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Input Power</b> : Up to 90kW
            </p>
            <p className="fs-1">
              {" "}
              <b>Output Torque</b> : 65,000 N-m{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Gear Ratios</b> : 3.6:1 to 3200:1- Single/Double Reduction
            </p>
            <p className="fs-1">
              {" "}
              <b>Motor Sizes IEC Frames from </b> : 90 to 200
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PLANETARY_GEARED_MOTOR;
