import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function AboutUs() {
  const options = {
    items: 1,
    margin: 30,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    dots: true,
    center: true,
    nav: false, // Disable default navigation
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <section
        className="page-title"
        style={{ backgroundImage: "url(images/background/2.jpg)" }}
      >
        <div className="auto-container">
          <h1>About Us</h1>
          <ul className="page-breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>About Us</li>
          </ul>
        </div>
      </section>

      <section className="about-section-two">
        <div className="auto-container">
          <div
            className="outer-box clearfix"
            style={{ backgroundImage: "url(images/background/4.jpg)" }}
          >
            <div className="highlight-box">
              <OwlCarousel className="info-carousel owl-carousel" {...options}>
                <div className="about-block-two">
                  <div className="icon-box">
                    <span className="icon flaticon-house"></span>
                  </div>
                  <h3>Smart Technologyy</h3>
                  <div className="text">
                    We utilizes cutting-edge technology to deliver innovative
                    and efficient engineering solutions.
                  </div>
                </div>

                <div className="about-block-two">
                  <div className="icon-box">
                    <span className="icon flaticon-house"></span>
                  </div>
                  <h3>Punctual Delivery Time</h3>
                  <div className="text">
                    We ensures timely delivery of projects, meeting deadlines
                    with precision and reliability.
                  </div>
                </div>

                <div className="about-block-two">
                  <div className="icon-box">
                    <span className="icon flaticon-house"></span>
                  </div>
                  <h3>Team of Professionals</h3>
                  <div className="text">
                    KVM Engineers boasts a skilled team of experts dedicated to
                    delivering top-notch engineering services.
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <section className="story-section">
        <div className="auto-container">
          <div className="sec-title">
            <h2>About Us</h2>
          </div>
          <div className="row clearfix">
            <div className="content-column col-md-12 col-sm-12 col-xs-12">
              <div className="inner-column">
                <div className="text">
                  <p>
                    Welcome to KVM Engineers Private Limited, your trusted
                    partner in high-quality engineering solutions. Established
                    with a vision to provide top-notch products and exceptional
                    service, we are proud to be an authorized stockist of Elecon
                    and Radicon, two of the most renowned brands in the
                    engineering industry.
                  </p>
                  <p>
                    At KVM Engineers, we specialize in supplying an extensive
                    range of engineering products including gearboxes, gear
                    motors, and couplings. Our product offerings are
                    meticulously sourced from industry leaders Elecon and
                    Radicon, ensuring that our customers receive only the most
                    reliable and efficient solutions for their mechanical and
                    industrial needs.
                  </p>

                  <h3>Our Product Range</h3>
                  <p>
                    <b>Gearboxes</b>: We offer a comprehensive selection of
                    gearboxes designed for various applications, from simple
                    machinery to complex industrial systems. Our gearboxes are
                    engineered for durability, precision, and performance,
                    making them ideal for enhancing the efficiency of your
                    operations.
                  </p>
                  <p>
                    <b>Gear Motors</b>: Our gear motors are built to provide
                    robust and reliable performance across diverse industrial
                    applications. Combining advanced technology with quality
                    craftsmanship, our gear motors deliver the power and
                    efficiency you need for your projects.
                  </p>
                  <p>
                    <b>Couplings</b>: We provide a wide array of couplings to
                    ensure smooth and efficient transmission of power between
                    machinery components. Our couplings are engineered for
                    strength and reliability, catering to a range of industrial
                    requirements.
                  </p>
                  <h3>Our Commitment</h3>
                  <p>
                    At KVM Engineers, we are dedicated to offering not only
                    high-quality products but also exceptional customer service.
                    Our knowledgeable team is committed to understanding your
                    unique needs and providing tailored solutions that meet your
                    specifications. We prioritize building long-term
                    relationships with our clients through integrity,
                    reliability, and excellence.
                  </p>
                  <h3>Why Choose Us?</h3>

                  <p>
                    {" "}
                    <b> Authorized Stockists</b>: As authorized stockists of
                    Elecon and Radicon, we guarantee that you will receive
                    genuine products with the highest standards of quality.
                  </p>
                  <p>
                    <b> Expertise and Experience</b>: Our team brings a wealth
                    of experience in the engineering sector, ensuring expert
                    advice and support for all your technical requirements.
                  </p>
                  <p>
                    {" "}
                    <b> Customer-Centric Approach</b>: We strive to exceed
                    customer expectations by delivering timely solutions and
                    personalized service.
                  </p>

                  <p>
                    Discover how KVM Engineers can support your engineering
                    needs. Explore our product range and contact us today to
                    learn more about how we can assist with your next project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
