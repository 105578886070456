import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./owlcarasol.css";

function MultipliSlider() {
  const options = {
    items: 3,
    margin: 30,
    loop: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    dots: true,
    center: true,
    nav: false, // Disable default navigation
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <section className="services-section">
      <div className="auto-container">
        <div className="sec-title text-center">
          <h4>Our Services</h4>
          <h2>Our Market Sectors</h2>
        </div>

        <OwlCarousel className="services-carousel owl-theme" {...options}>
          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/sugar.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Sugar Industries</h3>
                  <i className="icon flaticon-conveyor"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/power-plant.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Power Plants</h3>
                  <i className="icon flaticon-wind-mill"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/minning.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Mining Industry</h3>
                  <i className="icon flaticon-industry"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/fmcg.jpeg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>FMCG Manufaturing Units</h3>
                  <i className="icon flaticon-factory-1"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/oem.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>OEM Units</h3>
                  <i className="icon flaticon-solar-energy"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/cement.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Cement Industry</h3>
                  <i className="icon flaticon-gears"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/paper.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Paper Industry</h3>
                  <i className="icon flaticon-gear"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/chemical.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Chemical & Fertiliser</h3>
                  <i className="icon flaticon-factory-1"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block">
            <div className="inner-box">
              <div className="image-box">
                <figure>
                  <img src="images/Industry/aluminium.jpg" alt=""></img>
                </figure>

                <div className="title">
                  <h3>Aluminium Industry</h3>
                  <i className="icon flaticon-factory-1"></i>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
        <br></br>
        <div className="info-box">
          <h3>
            Since 1997, We provide best solutions for our valuable customers.
          </h3>
        </div>
      </div>
    </section>
  );
}

export default MultipliSlider;
