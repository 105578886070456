import React from "react";

function FLUID_COUPLING() {
  return (
    <>
      <div className="container p-5">
        <h1>FLUID COUPLING</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/FLUID-COUPING.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
            Fluid Coupling Ideally suitable for smooth and jerkless start 
and also act as energy saver.

            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default FLUID_COUPLING;
