import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import styles from "./CustomCarousel.module.css"; // Import the CSS module

const CustomCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      fade
      indicators={false}
      interval={3000}
      keyboard
      pause="hover"
      wrap
      prevIcon={
        <span
          className={`${styles.customIcon} ${styles.prevIcon}`}
          aria-hidden="true"
        >
          &#10094; {/* Custom icon: left arrow */}
        </span>
      }
      nextIcon={
        <span
          className={`${styles.customIcon} ${styles.nextIcon}`}
          aria-hidden="true"
        >
          &#10095; {/* Custom icon: right arrow */}
        </span>
      }
    >
      <Carousel.Item>
        <div
          className={styles.carouselBackground}
          style={{ backgroundImage: "url(images/main-slider/banner-1.jpg)" }}
        >
          <div
            className={`${styles.carouselContent} ${styles.left}  animated fadeInUp`}
          >
            <h4>Warm welcome to</h4>
            <h1>KVM ENGINEERS</h1>
            <p>Torque Transmission & Solutions</p>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          className={styles.carouselBackground}
          style={{ backgroundImage: "url(images/main-slider/banner-1-2.jpg)" }}
        >
          <div
            className={`${styles.carouselContent} ${styles.right} ${styles.righttext}  animated fadeInUp`}
          >
            <h4>Warm welcome to</h4>
            <h1>KVM ENGINEERS</h1>
            <p>Torque Transmission & Solutions</p>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          className={styles.carouselBackground}
          style={{ backgroundImage: "url(images/main-slider/banner-1-3.jpg)" }}
        >
          <div
            className={`${styles.carouselContent} ${styles.left}  animated fadeInUp`}
          >
            <h4>Warm welcome to</h4>
            <h1>KVM ENGINEERS</h1>
            <p>Torque Transmission & Solutions</p>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default CustomCarousel;
