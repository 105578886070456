import React from "react";

function KvmOrganique() {
  return (
    <div className="commingsoon">
      <h1>Coming Soon..</h1>
    </div>
  );
}

export default KvmOrganique;
