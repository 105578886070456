import React from "react";

function ELFLEX_FLEXIBLE_COUPLING() {
  return (
    <>
      <div className="container p-5">
        <h1>ELFLEX FLEXIBLE COUPLING</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/ELFLEX-FLEXIBLE-COUPLING.png"></img>
          </div>

          <div className="col-md-7 mt-5">
          <div className="row">
          <table >
        <tr >
            <th ><h2 className="fs-3  mt-5">Type</h2></th>
            <th><h2 className="fs-3  mt-5">Size</h2></th>
            <th><h2 className="fs-3  mt-5">Torque</h2> </th>
            <th><h2 className="fs-3  mt-5">Kw/rpm</h2></th>
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3">EFC – Flexible Coupling    </td>
            <td className="fs-5  mt-5 pt-3">EFC 01 to EFC 17</td>
            <td className="fs-5  mt-5 pt-3">70 to 27060 Nm	</td>
            <td className="fs-5  mt-5 pt-3">0.007 to 2.835 kW/RPM</td>
            
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3">FC 630 to FC 1600</td>
            <td className="fs-5  mt-5 pt-3">FC   – Flexible Coupling</td>
            <td className="fs-5  mt-5 pt-3">32000 to 430000 N.m</td>
            <td className="fs-5  mt-5 pt-3">3.35 to 44.77 kW/RPM</td>
            
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3 ">FBC – Flexible Brake Drum Coupling</td>
            <td className="fs-5  mt-5 pt-3">FBC 100 to FBC 500</td>
            <td className="fs-5  mt-5 pt-3">120 to 9830 N.m.</td>
            <td className="fs-5  mt-5 pt-3">.016 to 1.38 kW/RPM</td>
            
        </tr>
        
    </table>
          </div>        
          </div>
          
        </div>
      </div>
    </>
  );
}

export default ELFLEX_FLEXIBLE_COUPLING;
