import React from "react";

function HELICAL_GEARBOXES() {
  return (
    <>
      <div className="container p-5">
        <h1>HELICAL_GEARBOXES</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/HELICAL%20GEARBOXES.png"></img>
          </div>

          <div className="col-md-7 mt-5">
          <h1>HELICAL GEAR UNITS</h1>
          <hr></hr>
            <p className="fs-1">
              <b> Ratio</b> : 1.25 to 560
            </p>
            <p className="fs-1">
              <b>Torque</b> :upto 1400 kNm
            </p>
            <br></br>
            <h1 className="mt-5">BEVEL HELICAL GEAR UNITS</h1>
          <hr></hr>
            <p className="fs-1">
              <b> Ratio</b> : 5:1 to 560
            </p>
            <p className="fs-1">
              <b>Torque</b> :upto 1400 kNm
            </p>
           
           
          </div>
          <div className="col-md-12">
            <p className="fs-2 mt-5 text-justify">
            Available with high torque and various gear ratios. Single 
stage to four stages in helical configuration. Two stages to 
four stages in bevel helical design. Options for solid shaft 
with keyway, hollow shaft with keyway or shrink disc, and
foot or flange mounting.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HELICAL_GEARBOXES;
