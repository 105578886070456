import React from "react";

function WORM_GEAR_REDUCERS() {
  return (
    <>
      <div className="container p-5">
        <h1>PBWR SERIES - WORM GEAR REDUCERS </h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/WORM-GEAR-REDUCERS.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Unit Sizes</b> : 25, 30, 40, 50, 63, 75, 90, 110, 130 and 150{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Input Power</b> : Up to 15kW
            </p>
            <p className="fs-1">
              {" "}
              <b>Output Torque</b> : Up to 1760 N-m{" "}
            </p>
            <p className="fs-1">
              {" "}
              <b>Gear Ratios</b> : Up to 100:1
            </p>
            <p className="fs-1">
              {" "}
              <b>Motor Sizes IEC Frames from </b> :  56 to 160
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WORM_GEAR_REDUCERS;
