import React, { useState } from "react";
import "animate.css";

function AboutOurCompany() {
  const [activeTab, setActiveTab] = useState("info-tab-1");
  const [animationClass, setAnimationClass] = useState("");

  const handleTabClick = (tabId) => {
    if (activeTab !== tabId) {
      setAnimationClass("animate__animated animate__fadeIn"); // Add the animation class
      setActiveTab(tabId);
    }
  };

  return (
    <section className="about-us">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="info-column col-md-6 col-sm-12 col-xs-12">
            <div className="sec-title">
              <h4>Company</h4>
              <h2>About Our Company</h2>
            </div>
            <div className="about-company">
              <h4>
                We are authorized stockists for Elecon Engineering Co. Limited
                and Radicon Power Build Pvt. Limited, serving since 1997.
              </h4>
              <div className="info-tabs tabs-box">
                <ul className="tab-buttons">
                  <li
                    className={`tab-btn ${
                      activeTab === "info-tab-1" ? "active-btn" : ""
                    }`}
                    onClick={() => handleTabClick("info-tab-1")}
                  >
                    About Company
                  </li>
                  <li
                    className={`tab-btn ${
                      activeTab === "info-tab-2" ? "active-btn" : ""
                    }`}
                    onClick={() => handleTabClick("info-tab-2")}
                  >
                    Our Mission
                  </li>
                  <li
                    className={`tab-btn ${
                      activeTab === "info-tab-3" ? "active-btn" : ""
                    }`}
                    onClick={() => handleTabClick("info-tab-3")}
                  >
                    Our Vision
                  </li>
                </ul>

                <div className="tabs-content">
                  <div
                    className={`tab ${
                      activeTab === "info-tab-1" ? "active-tab" : ""
                    } ${animationClass}`}
                    id="info-tab-1"
                    onAnimationEnd={() => setAnimationClass("")} // Remove the animation class after animation ends
                  >
                    <div className="text">
                      Welcome to KVM Engineers Private Limited, your trusted
                      partner in high-quality engineering solutions. Established
                      with a vision to provide top-notch products and
                      exceptional service...
                    </div>
                  </div>

                  <div
                    className={`tab ${
                      activeTab === "info-tab-2" ? "active-tab" : ""
                    } ${animationClass}`}
                    id="info-tab-2"
                    onAnimationEnd={() => setAnimationClass("")} // Remove the animation class after animation ends
                  >
                    <div className="text">
                      At KVM Engineers Private Limited, our mission is to
                      empower customers with top-quality engineering products
                      and solutions, driving superior operational efficiency and
                      success.
                    </div>
                  </div>

                  <div
                    className={`tab ${
                      activeTab === "info-tab-3" ? "active-tab" : ""
                    } ${animationClass}`}
                    id="info-tab-3"
                    onAnimationEnd={() => setAnimationClass("")} // Remove the animation class after animation ends
                  >
                    <div className="text">
                      To revolutionise the industry by delivering unparalleled
                      reliability, performance, and innovation, driving the
                      future of mechanical power transmission while prioritizing
                      sustainability and exceeding customer expectations
                    </div>
                  </div>
                </div>
              </div>
              <div className="link-box">
                <a href="market-sector-single.html">
                  <i className="machinery-icon-next"></i> Read More
                </a>
              </div>
            </div>
          </div>

          <div className="list-column col-md-6 col-sm-12 col-xs-12">
            <div className="inner-column">
              <div className="about-block">
                <div className="inner-box">
                  <span className="icon icon-gear"></span>
                  <h4>
                    <a href="market-sector.html">Smart Technology</a>
                  </h4>
                  <div className="text fs-3">
                    We utilizes cutting-edge technology to deliver innovative
                    and efficient engineering solutions.
                  </div>
                </div>
              </div>

              <div className="about-block">
                <div className="inner-box">
                  <span className="icon icon-clock"></span>
                  <h4>
                    <a href="market-sector.html">Punctual Delivery Time</a>
                  </h4>
                  <div className="text fs-3">
                    We ensures timely delivery of projects, meeting deadlines
                    with precision and reliability.
                  </div>
                </div>
              </div>

              <div className="about-block">
                <div className="inner-box">
                  <span className="icon icon-worker"></span>
                  <h4>
                    <a href="market-sector.html">Team of Professionals</a>
                  </h4>
                  <div className="text fs-3">
                    KVM Engineers boasts a skilled team of experts dedicated to
                    delivering top-notch engineering services.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutOurCompany;
