import React from "react";

function WORM_GEAR_SMALL_SERIES() {
  return (
    <>
      <div className="container p-5">
        <h1>WORM GEAR SMALL SERIES</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/WORM-GEAR-SMALL-SERIES.png"></img>
          </div>

          <div className="col-md-7 mt-5">
            <p className="fs-1">
              <b> Underdriven</b> : SNU-U
            </p>
            <p className="fs-1">
              {" "}
              <b>Overdriven</b> : SNU-O 
            </p>
            <p className="fs-1">
              {" "}
              <b>Verticle output shaft up/down</b> : SNU-V
            </p>
            <p className="fs-1">
              {" "}
              <b>Hollow output shaft</b> : SNU-SM
            </p>
            <p className="fs-1">
              {" "}
              <b>Sizes </b> : 1 5/8” to 3.54”
            </p>
            <p className="fs-1">
              {" "}
              <b>Ratio </b> : Min. 5 : 1 to Max 70 : 1
            </p>
            <p className="fs-1">
              {" "}
              <b>Power Capacity </b> : 12.5 kW 
            </p>
          </div>
          <div className="col-md-12">
            <p className="fs-2 mt-5 text-justify">
            As Asia's one of the largest industrial Gear Manufacturers, Elecon 
Worm Gear Small Series is a range of compact and lightweight worm
gearboxes that are designed for a variety of applications. These 
gearboxes offer high torque capacity and smooth operation in a 
small package.
</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WORM_GEAR_SMALL_SERIES;
