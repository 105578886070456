import React from "react";
import CustomCarousel from "../Components/Carosoal/CustomCarousel";
import { Helmet } from "react-helmet";
import MultipliSlider from "../Components/MultiSlider/MultipliSlider";
import Whatwedo from "../Components/Whatwedo";
import AboutOurCompany from "../Components/AboutOurCompany";
import Ourproducts from "../Components/MultiSlider/Ourproducts";
import RequestafreeQuate from "../Components/RequestafreeQuate";
import $ from "jquery";
window.$ = $;
window.jQuery = $;

function Home() {
  return (
    <div>
      <Helmet>
        <title>KVM ENGINEERS</title>
        <meta
          name="description"
          content="KVM ENGINEERS offers top-quality torque transmission solutions and engineering services. Explore our innovative products and expertise in mechanical power transmission for various industrial applications."
        />
      </Helmet>
      <CustomCarousel />
      <MultipliSlider />
      <Whatwedo />
      <AboutOurCompany />
      <Ourproducts />
      <RequestafreeQuate />
    </div>
  );
}

export default Home;
