import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const useContactForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://docs.google.com/forms/d/e/1FAIpQLSfJe3fQfppDlCzm9Aq6EBxaB8_sEjvm2iWn7GiQLC-q09TSpg/formResponse",
        null,
        {
          params: {
            "entry.764022845": formData.username,
            "entry.2025047741": formData.email,
            "entry.752992785": formData.mobile,
            "entry.1340492618": formData.message,
          },
        }
      );
      if (response.status === 200) {
        // Show SweetAlert2 success message
        Swal.fire({
          title: "Thank You!",
          text: "Your form has been submitted successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Clear form data
        setFormData({
          username: "",
          email: "",
          mobile: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: "Thank You!",
        text: "Your form has been submitted successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });

      setFormData({
        username: "",
        email: "",
        mobile: "",
        message: "",
      });

      //   setError("Error submitting form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    isSubmitting,
    error,
    handleChange,
    handleSubmit,
  };
};

export default useContactForm;
