import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "animate.css";
import "./header.css";
import { useNavigate } from "react-router-dom";

function Header2() {
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  const [isScrolled, setIsScrolled] = useState(isMobileDevice);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuNav, setisMenuNav] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contacts");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (!isMobileDevice) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileDevice]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div
      className={`headers ${
        isScrolled ? "fixed-header animate__animated animate__fadeInDown" : ""
      }`}
    >
      <div
        className={`mytop-bar ${
          isScrolled ? "hidden" : "animate__animated animate__fadeInDown"
        }`}
      >
        <div className="row">
          <div className="col-md-3 topone">
            <img src="images/logo1w.png" alt="Logo" className="img-fluid" />
          </div>
          <div className="col-md-3 toptwo iconboxcontainer">
            <div className="iconbox">
              <i className="fa-solid fa-location-dot"></i>
              <div>
                <p className="text-white">
                  <span>
                    <b className="text-white">
                      KVM House F-152 Transport Nagar
                    </b>
                  </span>
                  <br />
                  Lucknow-226012 Uttar Pradesh India
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 topthree iconboxcontainer">
            <div className="iconbox">
              <i className="fas fa-mobile-alt"></i>
              <div>
                <p className="text-white">
                  <span>
                    <b>+91 9651103223</b>
                  </span>{" "}
                  <br />
                  Mon-Sat : 10.00 am to 06.00 pm.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 topfour iconboxcontainer">
            <ul>
              <li>
                <a href="#">
                  <i className="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mynavbarContainer">
        <div className="mynavbar">
          {isScrolled ? (
            <img
              src="images/logo1w.png"
              alt="Logo"
              className="img-fluid w-25"
            />
          ) : (
            ""
          )}

          <ul className={`mynavbarul ${isMenuOpen ? "open" : ""}`}>
            <li>
              <Link to="/" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleLinkClick}>
                About Us
              </Link>
            </li>
            {/* <li className="dropdown">
              <Link to="/products">Products</Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#">Products 11</Link>
                </li>
                <li>
                  <Link to="#">Products 12</Link>
                </li>
                <li>
                  <Link to="#">Products 13</Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to="/products" onClick={handleLinkClick}>
                Products
              </Link>
            </li>
            <li>
              <Link to="/contacts" onClick={handleLinkClick}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/kvm-surgicals" onClick={handleLinkClick}>
                KVM Surgicals
              </Link>
            </li>
            <li>
              <Link to="/kvm-organic" onClick={handleLinkClick}>
                KVM Organique
              </Link>
            </li>
          </ul>
          <button className="getbutton" onClick={handleClick}>
            Get a Quote
          </button>
          <div
            className={`navToggle ${isMenuOpen ? "openNav" : ""}`}
            onClick={toggleMenu}
          >
            <i className={isMenuOpen ? "fas fa-times" : "fas fa-stream"}></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header2;
