import React from "react";

function ELIGN_GEARED_COUPLING() {
  return (
    <>
      <div className="container p-5">
        <h1>ELIGN GEARED COUPLING</h1>
        <hr></hr>
        <div className="row">
          <div className="col-md-5">
            <img src="./images/ProImages/ELIGN-GEARED-COUPLING.png"></img>
          </div>

          <div className="col-md-7 mt-5">
          <div className="row">
          <table >
        <tr >
            <th ><h2 className="fs-3  mt-5">Type</h2></th>
            <th><h2 className="fs-3  mt-5">Size</h2></th>
            <th><h2 className="fs-3  mt-5">Torque</h2> </th>
            <th><h2 className="fs-3  mt-5">Kw/rpm</h2></th>
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3">ED –with double housing        </td>
            <td className="fs-5  mt-5 pt-3">ED 130 to ED 110000</td>
            <td className="fs-5  mt-5 pt-3">1300 to 1100000 nm            	</td>
            <td className="fs-5  mt-5 pt-3">0.136 to 115.19kv/rpm</td>
            
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3">ER –with one end rigid          </td>
            <td className="fs-5  mt-5 pt-3">ER 130 to ER 45000</td>
            <td className="fs-5  mt-5 pt-3">1300 to 450000 nm              </td>
            <td className="fs-5  mt-5 pt-3">0.136 to 47.123kv/rpm</td>
            
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3 ">ET –with spacer                     </td>
            <td className="fs-5  mt-5 pt-3">ET 130 to ET 110000</td>
            <td className="fs-5  mt-5 pt-3">1300 to 1100000 nm</td>
            <td className="fs-5  mt-5 pt-3">0.136 to 115.19kv/rpm</td>
             
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3 ">EV –for vertical application                     </td>
            <td className="fs-5  mt-5 pt-3">EV 130 to EV 17400 </td>
            <td className="fs-5  mt-5 pt-3">1300 to 174000 nm</td>
            <td className="fs-5  mt-5 pt-3">0.136 to 18.221/rpm</td>
           
        </tr>
        <tr>
            <td className="fs-5  mt-5 pt-3 ">ES –with single housing                           </td>
            <td className="fs-5  mt-5 pt-3">ES 1600 to ES 17400</td>
            <td className="fs-5  mt-5 pt-3">16000 to 174000 nm</td>
            <td className="fs-5  mt-5 pt-3">1.675 to 174000kv/rpm</td>
           
        </tr>
    </table>
          </div>        
          </div>
        </div>
      </div>
    </>
  );
}

export default ELIGN_GEARED_COUPLING;
