import "./App.css";
import Footer from "./Components/Footer/Footer";
import Header2 from "./Components/Header/Headre2";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home";
import Products from "./Pages/Products";
import Contact from "./Pages/Contact";
import KvmSurgicals from "./Pages/KvmSurgicials";
import KvmOrganic from "./Pages/KvmOrganique";
import AboutUs from "./Pages/AboutUs";
import RIGHT_ANGLED_HELI_WORM_GEARED_MOTOR from "./Pages/Products/RIGHT_ANGLED_HELI_WORM_GEARED_MOTOR";
import PARALLEL_SHAFT_MOUNTED_GEARED_MORTOR from "./Pages/Products/PARALLEL_SHAFT_MOUNTED_GEARED_MOTOR";
import RIGHT_ANGLE_HELICAL_BEVEL_GEARED_MOTOR from "./Pages/Products/RIGHT_ANGLE_HELICAL_BEVEL_GEARED_MOTOR";
import WORM_GEAR_REDUCERS from "./Pages/Products/WORM_GEAR_REDUCERS";
import HOIST_GEARED_MOTOR from "./Pages/Products/HOIST_GEARED_MOTOR";
import PLANETARY_GEARED_MOTOR from "./Pages/Products/PLANETARY_GEARED_MOTOR";
import COMPACT_GEARED_MOTOR from "./Pages/Products/COMPACT_GEARED_MOTOR";
import HELICAL_GEARBOXES from "./Pages/Products/HELICAL_GEARBOXES";
import EON_SERIES from "./Pages/Products/EON_SERIES";
import CRANE_DUTY_GEAR_BOX from "./Pages/Products/CRANE_DUTY_GEAR_BOX";
import WORM_GEAR_HIGHER_SERIES from "./Pages/Products/WORM_GEAR_HIGHER_SERIES";
import WORM_GEAR_SMALL_SERIES from "./Pages/Products/WORM_GEAR_SMALL_SERIES";
import DER_RADICON_SERIES from "./Pages/Products/DER_RADICON_SERIES";
import HIGHLY_FLEXIBLE_COUPLINGS from "./Pages/Products/HIGHLY_FLEXIBLE_COUPLINGS";
import FLUID_COUPLING from "./Pages/Products/FLUID_COUPLING";
import SNU_MODULAR_UNIVERSAL_MOUNTING from "./Pages/Products/SNU_MODULAR_UNIVERSAL_MOUNTING";
import DOUBLE_REDUCTION_GEAR from "./Pages/Products/DOUBLE_REDUCTION_GEAR";
import ELIGN_GEARED_COUPLING from "./Pages/Products/ELIGN_GEARED_COUPLING";
import ELFLEX_FLEXIBLE_COUPLING from "./Pages/Products/ELFLEX_FLEXIBLE_COUPLING";
import HELICAL_INLINE_GEARED_MOTOR from "./Pages/Products/HELICAL_INLINE_GEARED_MOTOR";
import { useEffect, useState } from "react";
import ScrollToTop from "./Pages/ScrollToTop";
import Loader from "./Pages/Loader.js";
import ContactUs2 from "./Pages/ContactUs2.js";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const handleScroll = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    handleScroll();

    // Mimic async data fetching
    setTimeout(() => {
      setLoading(false);
    }, 300); // Adjust the duration to match your actual data fetching time
  }, [location]);

  return (
    <>
      <div className="page-wrapper">
        {loading && <Loader />}
        <Header2 />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contacts" element={<ContactUs2 />} />
          <Route path="/kvm-surgicals" element={<KvmSurgicals />} />
          <Route path="/kvm-organic" element={<KvmOrganic />} />
          <Route
            path="/HELICAL_INLINE_GEARED_MOTOR"
            element={<HELICAL_INLINE_GEARED_MOTOR />}
          />
          <Route
            path="/RIGHT_ANGLED_HELI_WORM_GEARED_MOTOR"
            element={<RIGHT_ANGLED_HELI_WORM_GEARED_MOTOR />}
          />
          <Route
            path="/PARALLEL_SHAFT_MOUNTED_GEARED_MORTOR"
            element={<PARALLEL_SHAFT_MOUNTED_GEARED_MORTOR />}
          />
          <Route
            path="/RIGHT_ANGLE_HELICAL_BEVEL_GEARED_MOTOR"
            element={<RIGHT_ANGLE_HELICAL_BEVEL_GEARED_MOTOR />}
          />
          <Route path="/WORM_GEAR_REDUCERS" element={<WORM_GEAR_REDUCERS />} />
          <Route path="/HOIST_GEARED_MOTOR" element={<HOIST_GEARED_MOTOR />} />
          <Route
            path="/PLANETARY_GEARED_MOTOR"
            element={<PLANETARY_GEARED_MOTOR />}
          />
          <Route
            path="/COMPACT_GEARED_MOTOR"
            element={<COMPACT_GEARED_MOTOR />}
          />
          <Route path="/HELICAL_GEARBOXES" element={<HELICAL_GEARBOXES />} />
          <Route path="/EON_SERIES" element={<EON_SERIES />} />
          <Route
            path="/CRANE_DUTY_GEAR_BOX"
            element={<CRANE_DUTY_GEAR_BOX />}
          />
          <Route
            path="/WORM_GEAR_HIGHER_SERIES"
            element={<WORM_GEAR_HIGHER_SERIES />}
          />
          <Route
            path="/WORM_GEAR_SMALL_SERIES"
            element={<WORM_GEAR_SMALL_SERIES />}
          />
          <Route path="/DER_RADICON_SERIES" element={<DER_RADICON_SERIES />} />
          <Route
            path="/HIGHLY_FLEXIBLE_COUPLINGS"
            element={<HIGHLY_FLEXIBLE_COUPLINGS />}
          />
          <Route path="/FLUID_COUPLING" element={<FLUID_COUPLING />} />

          <Route
            path="/SNU_MODULAR_UNIVERSAL_MOUNTING"
            element={<SNU_MODULAR_UNIVERSAL_MOUNTING />}
          />

          <Route
            path="/DOUBLE_REDUCTION_GEAR"
            element={<DOUBLE_REDUCTION_GEAR />}
          />

          <Route
            path="/ELIGN_GEARED_COUPLING"
            element={<ELIGN_GEARED_COUPLING />}
          />

          <Route
            path="/ELFLEX_FLEXIBLE_COUPLING"
            element={<ELFLEX_FLEXIBLE_COUPLING />}
          />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

const WrappedApp = () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);

export default WrappedApp;
